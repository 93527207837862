import { VenueMenuCategoryQuery } from 'lib/gql'
import flow from 'lodash/fp/flow'
import identity from 'lodash/fp/identity'
import sortBy from 'lodash/fp/sortBy'
import memoize from 'lodash/memoize'
import shuffle from 'lodash/shuffle'

type SortableMenuCategory<T> = Pick<
  VenueMenuCategoryQuery['guestMenuCategories'][number],
  'id' | 'position' | 'mvoFeatured' | 'isClosed'
> &
  T

export const randomizeCategories = <T>(
  menuCategories: SortableMenuCategory<T>[],
  randomize?: boolean,
  randomizer = shuffleOnChange,
): SortableMenuCategory<T>[] => {
  /**
   * Note: Shuffle comes first so that we do not randomize on search
   */
  const randomized: SortableMenuCategory<T>[] = flow(
    randomize ? randomizer : identity,
  )(menuCategories)

  const { featured, closed, other } = randomized.reduce(
    categoryMetaGroupsReducer,
    createCategoryMetaGroups<T>(),
  )

  const featuredSorted = featured.sort(sortByPosition)
  const closedSorted = closed.sort(sortByPosition)

  return [...featuredSorted, ...other, ...closedSorted]
}

const categoryChangeResolver = <T>(
  menuCategories: SortableMenuCategory<T>[],
): string => {
  if (!Array.isArray(menuCategories)) {
    return JSON.stringify(menuCategories)
  }

  /*
      This needs to include any dynamic properties that can change based on the table number.
      We could JSON.stringify the whole object to be safe but there
      might be a performance hit. Do we know of any other properties which are dynamic?
    */
  return menuCategories
    .map(
      (menuCategory) =>
        `id:${menuCategory.id},isClosed:${menuCategory.isClosed}`,
    )
    .join('')
}

const shuffleOnChange = memoize(
  flow(shuffle, sortBy('isClosed')),
  categoryChangeResolver,
)

type Positioned = { position?: null | number }
const sortByPosition = (a: Positioned, b: Positioned): number =>
  (a?.position || 0) - (b?.position || 0)

type CategoryMetaGroups<T> = {
  featured: SortableMenuCategory<T>[]
  closed: SortableMenuCategory<T>[]
  other: SortableMenuCategory<T>[]
}
const createCategoryMetaGroups = <T>(): CategoryMetaGroups<T> => ({
  featured: [],
  closed: [],
  other: [],
})
const categoryMetaGroupsReducer = <T>(
  acc: CategoryMetaGroups<T>,
  c: SortableMenuCategory<T>,
): CategoryMetaGroups<T> => {
  if (c.mvoFeatured) {
    acc.featured.push(c)
    return acc
  }

  if (c.isClosed) {
    acc.closed.push(c)
    return acc
  }

  acc.other.push(c)
  return acc
}
