import { CartUpsellInput } from 'lib/gql'

import { ItemGroupMap } from '../types'
import { mapModifiersValueToInput } from './mapModifiersValueToInput'

export const mapUpsellsValueToInput = (
  optionGroup: ItemGroupMap,
): CartUpsellInput[] => {
  return Object.entries(optionGroup)
    .map<CartUpsellInput[]>(([upsellGroupId, values]) => {
      return values
        .filter(
          (item) => !!item?.id && item.id !== 'false' && item.quantity > 0,
        )
        .map((item) => ({
          id: item.id,
          quantity: item.quantity,
          upsellGroupId,
          modifiers: mapModifiersValueToInput(
            Object.values(item.modifiers ?? {}).flat(),
          ),
          notes: item.notes,
        }))
    })
    .flat()
}
