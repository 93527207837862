import { createDinero } from '@mr-yum/frontend-core/dist/services/createDinero'
import { sendVisitorEvent } from 'components/Tracking/VisitorsTracking'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useCartMenuItem } from 'hooks/useCart'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useLogger } from 'hooks/useLogger'
import {
  AddToCartMutation,
  useMenuItemDetailsQuery,
  useMenuItemDetailsVenueQuery,
} from 'lib/gql'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { ecommerceEvent } from 'utils/gtag'

import { ItemDetailsBody } from './ItemDetailsBody'
import { ItemDetailsError } from './ItemDetailsError'
import { ItemDetailsSkeleton } from './ItemDetailsSkeleton'
import { ItemFormController } from './ItemForm'

export interface ItemDetailsProps {
  hasImage?: boolean
  handleClose(item?: AddToCartMutation['addToCart']): void
  startWithADrink?: boolean
}

export const ItemDetails = observer(
  ({ handleClose, hasImage, startWithADrink }: ItemDetailsProps) => {
    const { query } = useRouter()
    const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
    const { venueSlug } = useVenueContext()
    const categorySlug = String(query.category)
    const itemSlug = String(query.itemSlug)
    const { logEvent } = useLogger()

    const [{ data: venueData, error: venueError, fetching: venueFetching }] =
      useMenuItemDetailsVenueQuery({
        variables: {
          venueSlug,
          orderingType,
        },
      })

    const venue = venueData?.guestVenue

    const { cart, fetching: cartFetching } = useCartMenuItem({
      venueSlug,
      orderingType,
      pause: !venue,
    })

    const [{ data, error, fetching }] = useMenuItemDetailsQuery({
      variables: {
        venueSlug,
        categorySlug,
        itemSlug,
        orderingType,
        tableNumber: cart?.tableNumber ?? undefined,
        tableArea: cart?.tableArea ?? undefined,
        orderingWindowStartDate: cart?.orderingWindowStartDate ?? undefined,
        priceLevel: '',
      },
      pause: !query.itemSlug,
    })

    const menuItem = data?.guestMenuItem
    const category = data?.guestMenuCategory

    const menuItemWaitTime = menuItem?.extendedWaitTime
      ? menuItem.extendedWaitTime
      : undefined

    const orderingAvailable = (venue?.ordering &&
      venue?.isOrderingAvailable &&
      venue?.orderingType?.isAvailable &&
      !category?.isClosed) as boolean

    // Keep this value around for modal transitions
    const itemHasImage = useDebouncedValue(!!menuItem?.image || hasImage)
    const hasError = !!venueError || !!error
    const currency = venue?.currency ?? 'AUD'

    useEffect(() => {
      if (menuItem && menuItem.priceData.priceInCents && !hasError) {
        const value = createDinero(
          menuItem.priceData.priceInCents,
          currency,
        ).toUnit()

        if (menuItem.name) {
          void sendVisitorEvent(venueSlug, 'ViewContent', {
            value,
            currency,
            contentName: menuItem.name,
            contentType: 'product',
            contentIds: menuItem.name,
          })

          ecommerceEvent('view_item', {
            value,
            currency,
            items: [
              {
                index: 0,
                price: value,
                item_id: menuItem.id,
                item_name: menuItem.name,
              },
            ],
          })
        }
      }
    }, [currency, hasError, menuItem, venueSlug, logEvent, orderingAvailable])

    if (hasError) {
      return (
        <ItemDetailsError
          error={(venueError || error)!}
          venueSlug={venueSlug}
          orderingTypeSlug={orderingTypeSlug}
        />
      )
    }

    if (!menuItem || fetching || cartFetching || venueFetching) {
      return <ItemDetailsSkeleton hasImage={itemHasImage} />
    }

    return (
      <ItemFormController
        isAvailable={menuItem.isAvailableForOrdering}
        isItemLevelNotesEnabled={category?.isItemLevelNotesEnabled ?? false}
        itemSlug={itemSlug}
        handleClose={handleClose}
        menuItem={menuItem}
        cart={cart}
        priceLevel=""
        menuItemWaitTime={menuItemWaitTime}
        startWithADrink={startWithADrink}
        currency={currency}
      >
        <ItemDetailsBody
          itemSlug={itemSlug}
          orderingAvailable={orderingAvailable}
          menuItemWaitTime={menuItemWaitTime}
        />
      </ItemFormController>
    )
  },
)

ItemDetails.displayName = 'ItemDetails'
