import { yupResolver } from '@hookform/resolvers/yup'
import React, { ReactNode, useEffect, useMemo } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { FormValues } from '../types'
import { getItemGroupInitialValues } from '../utils/getItemGroupInitialValues'
import { getUpgradeGroupInitialValues } from '../utils/getUpgradeGroupInitialValues'
import { getValidationSchema } from '../utils/getValidationSchema'
import { useItemFormControllerContext } from './ItemFormController'
import { QuantityProvider } from './QuantityProvider'

export const ItemForm = ({ children }: { children: ReactNode }) => {
  const {
    onSelectUpgrade,
    menuItem,
    upgradeId,
    groups,
    onSelectQuantity,
    defaultQuantity,
    fetching,
    upgradeFetching,
  } = useItemFormControllerContext()
  const intl = useIntl()
  const { modifierGroups, upgradeGroups, upsellGroups } = groups.menuItem

  const validationSchema = getValidationSchema(
    {
      menuItem,
      upgradeGroups,
      upsellGroups,
      modifierGroups,
    },
    intl,
  )

  const defaultValues = useMemo(
    () => ({
      item: {
        id: menuItem.id,
        quantity: defaultQuantity,
        notes: '',
      },
      upgrades: getUpgradeGroupInitialValues(
        upgradeGroups,
        'upgrades',
        upgradeId,
      ),
      modifiers: getItemGroupInitialValues(modifierGroups),
      upsells: getItemGroupInitialValues(upsellGroups),
    }),
    [
      menuItem.id,
      defaultQuantity,
      upgradeGroups,
      upgradeId,
      modifierGroups,
      upsellGroups,
    ],
  )

  const form = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const { control, getValues, reset } = form

  /**
   * We need to reset modifiers when an upgrade is selected
   * technically a different item now. All the logic below
   * is to do with forcing a refresh of modifiers whilst
   * retaining the selected item quantity.
   */
  const item = useWatch({ control, name: 'item' })
  const formUpgradeId = useWatch({
    control,
    name: `upgrades.${upgradeGroups[0]?.id}`,
  })

  useEffect(() => {
    onSelectQuantity(item.quantity)
  }, [item, onSelectQuantity])

  useEffect(() => {
    if (formUpgradeId !== upgradeId) {
      onSelectUpgrade(formUpgradeId ?? null)
    }
  }, [upgradeId, formUpgradeId, onSelectUpgrade])

  useEffect(() => {
    /*
      We want to preserve modifiers selections across upgrade selections
    */
    if (!fetching && !upgradeFetching) {
      const currentModifierSelections = Object.values(
        getValues('modifiers'),
      ).flat()
      reset({
        ...getValues(),
        modifiers: getItemGroupInitialValues(
          modifierGroups,
          currentModifierSelections,
        ),
      })
    }
  }, [modifierGroups, reset, getValues, fetching, upgradeFetching])

  return (
    <FormProvider {...form}>
      <QuantityProvider>{children}</QuantityProvider>
    </FormProvider>
  )
}
