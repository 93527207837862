import { yupResolver } from '@hookform/resolvers/yup'
import { menuItemSerializer } from 'components/Tracking/utils'
import { useLogger } from 'hooks/useLogger'
import { MenuItemSelectType } from 'lib/gql'
import { UpsellGroupPartsFragment } from 'lib/gql.types'
import React, { ReactNode, useCallback, useContext } from 'react'
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { useIntl } from 'react-intl'

import { useItemFormControllerContext } from './ItemForm'
import { QuantityContext } from './ItemForm/QuantityProvider'
import { SelectedUpsell } from './ItemForm/types'
import { FormValues } from './types'
import { getItemGroupInitialValues } from './utils/getItemGroupInitialValues'
import { getValidationSchema } from './utils/getValidationSchema'

export const AddUpsellForm = ({
  children,
  selectedUpsell,
  selectedUpsellGroup,
}: {
  children: ReactNode
  selectedUpsell: NonNullable<SelectedUpsell>
  selectedUpsellGroup: UpsellGroupPartsFragment
}) => {
  const { logEvent } = useLogger()
  const { handleClose, groups, menuItem } = useItemFormControllerContext()
  const { control } = useFormContext<FormValues>()
  const { fields, update, replace } = useFieldArray({
    control,
    name: `upsells.${selectedUpsellGroup.id}`,
    keyName: 'key',
  })
  const upsellIndex = fields.findIndex(
    (field) => field.id === selectedUpsell.id,
  )
  const upsellItem = fields[upsellIndex]
  const intl = useIntl()
  const { maxQuantity, minQuantity } = useContext(QuantityContext)

  const validationSchema = getValidationSchema(
    {
      menuItem: {
        inventoryEnabled: false,
        inventoryCount: null,
        minQuantity,
        maxQuantity,
      },
      upgradeGroups: [],
      upsellGroups: [],
      modifierGroups: groups.upsellItem.modifierGroups,
    },
    intl,
  )

  const form = useForm<FormValues>({
    defaultValues: {
      item: {
        id: upsellItem.id,
        quantity: upsellItem.quantity || 1,
        notes: upsellItem.notes,
      },
      modifiers: {
        ...getItemGroupInitialValues(groups.upsellItem.modifierGroups),
        ...upsellItem.modifiers,
      },
    },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    async (formValues) => {
      if (selectedUpsellGroup.selectType === MenuItemSelectType.Single) {
        replace(
          fields.map((item) => ({
            id: item.id,
            name: item.name,
            key: item.key,
            ...(item.id === formValues.item.id
              ? {
                  quantity: formValues.item.quantity,
                  notes: formValues.item.notes || undefined,
                  modifiers: formValues.modifiers,
                }
              : { quantity: 0, modifiers: {} }),
          })),
        )
      } else {
        update(upsellIndex, {
          ...upsellItem,
          quantity: formValues.item.quantity,
          notes: formValues.item.notes || undefined,
          modifiers: formValues.modifiers,
        })
      }

      const selectedUpsellsForTracking = Object.entries(formValues.modifiers)
        .map(([_upsellGroupId, values]) => {
          return values.filter(
            (item) => !!item?.id && item.id !== 'false' && item.quantity > 0,
          )
        })
        .flat()

      logEvent('Add "upsell with modifiers" item to order', {
        menuItem: menuItemSerializer(menuItem),
        upsell: {
          ...selectedUpsell,
          hasModifiers: selectedUpsellsForTracking.length > 0,
        },
      })

      handleClose()
    },
    [
      selectedUpsellGroup,
      logEvent,
      menuItem,
      selectedUpsell,
      handleClose,
      upsellIndex,
      fields,
      update,
      replace,
      upsellItem,
    ],
  )
  return (
    <FormProvider {...form}>
      <form
        className="flex-1"
        data-testid="item-options-form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  )
}
